import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Slot } from '../interfaces/slot-response.interface';
import { SaveSlotDetail } from './slot';

const SLOT_STATE = new StateToken<Slot>('slot');

@State<Slot>({
    name: SLOT_STATE,
    defaults: undefined
})

@Injectable()
export class SlotState {

    @Selector()
    static getSlotDetail(state: Slot): Slot {
        return state;
    }

    @Action(SaveSlotDetail)
    saveSlotDetail({ setState }: StateContext<Slot>, action: SaveSlotDetail) {
        const slot: Slot = action.slot;
        setState(slot);
    }
}
