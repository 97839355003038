import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { BatchStatus } from 'sharedPlan/enums/batch/batch-status.enum';
import { BatchPaginationActionSet, BatchPaginationActionReset } from './batch-actions.state';
import { BatchPaginationInterface } from './batch-data.interface';

export const batchPaginationInitialState: BatchPaginationInterface = {
  batchId: '',
  origin: null,
  status: BatchStatus.IN_PROGRESS,
  page: {
    pageIndex: 0,
    pageSize: 15,
    length: 15,
    previousPageIndex: 0,
  },
};

const BATCH_PAGINATION_STATE = new StateToken<BatchPaginationInterface>('batch_pagination_state');

@State<BatchPaginationInterface>({
  name: BATCH_PAGINATION_STATE,
  defaults: { ...batchPaginationInitialState },
})
@Injectable()
export class BatchPaginationState {
  @Selector()
  static get(state: BatchPaginationInterface): BatchPaginationInterface {
    return Object.keys(state).length > 0 ? state : undefined;
  }

  @Action(BatchPaginationActionSet)
  setData(ctx: StateContext<BatchPaginationInterface>, { batchPagination }: BatchPaginationActionSet) {
    ctx.setState(patch(batchPagination));
  }

  @Action(BatchPaginationActionReset)
  getReset(ctx: StateContext<BatchPaginationInterface | {}>) {
    ctx.setState(batchPaginationInitialState);
  }
}
