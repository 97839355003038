export enum BatchStatus {
  UNKNOWN = 'UNKNOWN',
  WAITING_PLAN = 'WAITING_PLAN',
  WAITING_QUOTATION = 'WAITING_QUOTATION',
  WAITING_QUOTATION_APPROVAL = 'WAITING_QUOTATION_APPROVAL',
  COMPLETED = 'COMPLETED',
  MANUALLY_FINISHED = 'MANUALLY_FINISHED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}
