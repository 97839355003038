import { BatchPaginationInterface } from './batch-data.interface';

export class BatchPaginationActionSet {
  static readonly type = '[BatchPagination] Define filter and pagination';
  constructor(public batchPagination: BatchPaginationInterface) {}
}

export class BatchPaginationActionReset {
  static readonly type = '[BatchPagination] Clear current filter and pagination';
  
}
