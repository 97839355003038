import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SchedActionReset, SchedActionSetData, SchedActionSetExternalId } from './scheduling-actions.state';
import { SchedulingData } from './scheduling-data.interface';

const SCHEDULING_STATE = new StateToken<SchedulingData>('scheduling');

@State<SchedulingData>({
  name: SCHEDULING_STATE,
  defaults: undefined,
})
@Injectable()
export class SchedulingState {
  @Selector()
  static get(state: SchedulingData): SchedulingData {
    return Object.keys(state).length > 0 ? state : undefined;
  }

  @Action(SchedActionSetExternalId)
  setExternalId(ctx: StateContext<SchedulingData>, { externalId }: SchedActionSetExternalId) {
    ctx.setState(
      patch({
        externalId,
      }),
    );
  }

  @Action(SchedActionSetData)
  setData(ctx: StateContext<SchedulingData>, { shedulingData }: SchedActionSetData) {
    ctx.setState(patch(shedulingData));
  }

  @Action(SchedActionReset)
  getReset(ctx: StateContext<SchedulingData>) {
    ctx.setState({});
  }
}
