import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { TravelPanelPaginationActionSet, TravelPanelPaginationActionReset } from './travel-panel.actions.state';
import { TravelPanelStoreInterface } from './travel-panel-store.interface';

export const travelPanelPaginationInitialState: TravelPanelStoreInterface = {
  id: null,
  plate: null,
  status: null,
  traderId: null,
  origin: null,
  terminal: null,
  driverName: null,
  driverDocument: null,
  carriers: null,
  batchId: null,
  shippingOrderError: null,
  loadScheduleError: null,
  page: {
    pageIndex: 0,
    pageSize: 10,
    length: 10,
    previousPageIndex: 0,
  },
};

const TRAVEL_PANEL_PAGINATION_STATE = new StateToken<TravelPanelStoreInterface>('travel_panel_pagination_state');

@State<TravelPanelStoreInterface>({
  name: TRAVEL_PANEL_PAGINATION_STATE,
  defaults: { ...travelPanelPaginationInitialState },
})
@Injectable()
export class TravelPanelPaginationState {
  @Selector()
  static get(state: TravelPanelStoreInterface): TravelPanelStoreInterface {
    return Object.keys(state).length > 0 ? state : undefined;
  }

  @Action(TravelPanelPaginationActionSet)
  setData(ctx: StateContext<TravelPanelStoreInterface>, { travelPanelPagination }: TravelPanelPaginationActionSet) {
    ctx.setState(patch(travelPanelPagination));
  }

  @Action(TravelPanelPaginationActionReset)
  getReset(ctx: StateContext<TravelPanelStoreInterface | {}>) {
    ctx.setState(travelPanelPaginationInitialState);
  }
}
