import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { fromEvent, merge } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ConnectionClearState, ConnectionStartMonitor } from './connection-actions.state';
import { ConnectionData } from './connection-data.interface';

const CONNECTION_STATE = new StateToken<ConnectionData>('connection');

@State<ConnectionData>({
    name: CONNECTION_STATE
})
@Injectable()
export class ConnectionState {

    @Selector()
    static isOnline(state: ConnectionData): boolean {
        return state.isOnline;
    }

    @Selector()
    static data(state: ConnectionData): ConnectionData {
        return state;
    }

    @Action(ConnectionStartMonitor)
    startMonitor(ctx: StateContext<ConnectionData>) {
        merge(
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        ).subscribe(isOnline => {
            ctx.setState({
                isOnline,
                showMessage: true
            });
        });
    }

    @Action(ConnectionClearState)
    clearState(ctx: StateContext<ConnectionData>) {
        ctx.setState({
            isOnline: navigator.onLine,
            showMessage: false
        });
    }
}
