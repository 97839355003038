import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { FlowOriginService } from "sharedCommon/services/flow-origin.service";
import { Errors } from "../enums/errors.enum";
import { Origin } from 'sharedCommon/enums/origin.enum';
import { ChapaService } from "../services/chapa.service";

@Injectable()
export class OriginErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly flowOriginService: FlowOriginService,
    private chapaService :ChapaService

  ) {}

  intercept(req: HttpRequest<HttpErrorResponse>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.goBackScheduling(req)) {
      return next.handle(req).pipe(catchError(this.catchError));
    }
    return next.handle(req);
  }

  private catchError = (error: HttpErrorResponse) => {
    if (error.error?.code !== Errors.TRUCK_NOT_FOUND) {
        this.router.navigate([ this.chapaService.isRouteScheduler], { state: { error: true } });
      if (error.status === 401) {
        return throwError(new HttpErrorResponse({}));
      }
    }
    return throwError(error);
  }

  private goBackScheduling(req): boolean {
    return this.flowOriginService.getOriginStore() === Origin.SCHUDELING &&
      this.router.url.includes('chapa') &&
      !this.router.url.includes('successful') &&
      !req.url.includes('pre-registration-cnh/image') &&
      !req.url.includes('/pre-registration-truck/image')
  }
}
