import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Origin } from "sharedCommon/enums/origin.enum";
import { FlowOriginService } from "sharedCommon/services/flow-origin.service";

@Injectable()
export class OriginUrlInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly flowOriginService: FlowOriginService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.includes(this.router.url, 'chapa') &&
      this.includes(req.url, 'truck-driver') &&
      !this.includes(req.url, 'origin') &&
      !this.includes(req.url, 'travels')
    ) {
      if (this.includes(this.router.url, 'driver-profile') && !this.includes(req.url, 'convert')) {
        this.flowOriginService.setOriginStore(Origin.FINDER);
      }
      const origin = `origin/${this.flowOriginService.getOriginStore().toUpperCase()}`;
      const newHttpRequest = new HttpRequest(
        <any>req.method,
        `${req.url}/${origin}`,
        req.body ?? null,
        { headers: req.headers, params: req.params }
      );
      return next.handle(newHttpRequest);
    }
    return next.handle(req);
  }

  includes = (url, value) => url.includes(value);
}
