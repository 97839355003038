import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReviewOptionResponse, ReviewRequest, ReviewResponse } from '../interfaces/review.interface';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  public review$: Observable<boolean>;

  private reviewSubject: Subject<boolean>;

  constructor(public http: HttpClient) {
    this.reviewSubject = new Subject();
    this.review$ = this.reviewSubject.asObservable();
  }

  triggerReview() {
    this.reviewSubject.next(true);
  }

  public getOptions(): Observable<ReviewOptionResponse[]> {
    return this.http.get<ReviewOptionResponse[]>(environment.API.REVIEW_OPTIONS);
  }

  public sendReview(reviewRequest: ReviewRequest): Observable<ReviewResponse> {
    return this.http.post<ReviewResponse>(environment.API.REVIEW, reviewRequest);
  }
}
