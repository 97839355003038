import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { FilterSaverActionSet, FilterSaverActionReset } from './filter-saver-actions.state';

export const filterSaverInitialState = {};

const FILTER_SAVER_STATE = new StateToken<any>('filter_saver_state');

@State<any>({
  name: FILTER_SAVER_STATE,
  defaults: { ...filterSaverInitialState },
})

@Injectable()
export class FilterSaverState {
  @Selector()
  static get(state) {
    return Object.keys(state).length > 0 ? state : undefined;
  }

  @Action(FilterSaverActionSet)
  setData(ctx: StateContext<any>, { filterSaverPagination }: FilterSaverActionSet) {
    ctx.setState(patch(filterSaverPagination));
  }

  @Action(FilterSaverActionReset)
  getReset(ctx: StateContext<{}>) {
    ctx.setState(filterSaverInitialState);
  }
}
