<trato-modal-action>
  <trato-modal-header
    title="Queremos saber:"
    subtitle="O que você está achando do Sistema de Agendamento Trato?"
  ></trato-modal-header>
  <trato-modal-body>
    <div class="review-modal__rates my-3">
      <app-review-rate-container #appReviewRateContainer></app-review-rate-container>
    </div>
    <ng-container *ngIf="selectedReviewCode">
      <div class="review-modal__answer">
        <div class="review-modal__answer__title">
          <ng-container class="title-small" *ngIf="isPositiveRate()"
            >Que bom! O que você mais gosta no SAT?
          </ng-container>
          <ng-container class="title-small" *ngIf="isNeutralRate()">O que podemos fazer para melhorar?</ng-container>
          <ng-container class="title-small" *ngIf="isNegativeRate()"
            >Poxa, sentimos muito! O que podemos fazer para melhorar?
          </ng-container>
        </div>
        <div class="review-modal__form">
          <form [formGroup]="formReview">
            <trato-form-field>
              <textarea
                tratoInput
                placeholder="Digite aqui..."
                #reviewDescription
                name="description"
                formControlName="description"
                [maxlength]="maxLengthDescription"
                rows="5"
                cols="60"
              ></textarea>
              <trato-input-error *ngIf="hasError(formReview.controls.description, 'required')">
                Campo obrigatório
              </trato-input-error>
            </trato-form-field>
          </form>
        </div>
      </div>
    </ng-container>
  </trato-modal-body>
  <div class="review-modal__buttons">
    <trato-modal-button (clickAction)="cancel()" text="Cancelar"></trato-modal-button>
    <trato-modal-button (clickAction)="send()" text="Enviar" [disabled]="!selectedReviewCode"></trato-modal-button>
  </div>
</trato-modal-action>
