import {
  Component,
  ElementRef,
  EventEmitter,
  Inject, Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewEncapsulation,
  HostBinding
} from '@angular/core';
import { REVIEW_RATE_CONTAINER } from '../constants/review-rate.constant';
import { ReviewRateContainerComponent } from '../review-rate-container/review-rate-container.component';
import { ReviewType } from '../types/review-rate.type';
import { ReviewCodeEnum } from '../enums/review.enum';

@Component({
  selector: 'app-review-rate',
  templateUrl: './review-rate.component.html',
  styleUrls: ['./review-rate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewRateItemComponent implements OnDestroy {

  @Input() id: ReviewCodeEnum;

  @Input() get color() {
    return this.reviewType;
  }
  set color(value: ReviewType) {
    this.setColor(value);
    this.reviewType = value;
  }

  @HostBinding('class.review-rate--selected')
  get selectedClass() {
    return this.isSelected;
  }

  @Input() get selected() {
    return this.isSelected;
  }

  set selected(value: boolean) {
    this.isSelected = !!value;
  }

  @Output() clickFn: EventEmitter<void> = new EventEmitter();

  private isSelected = false;
  private reviewType: ReviewType = 'default';

  constructor(
    @Inject(REVIEW_RATE_CONTAINER) public container: ReviewRateContainerComponent,
    private renderer2: Renderer2,
    private elementRef: ElementRef) {
    this.container.registerItem(this);
    this.renderer2.addClass(this.nativeElement, `review-rate`);
    this.setColor(this.reviewType);
  }

  ngOnDestroy(): void {
    this.container.removeItem(this);
  }

  onClickItem() {
    this.clickFn.emit();
  }

  private setColor(newReviewType: ReviewType) {
    if (this.reviewType) {
      this.renderer2.removeClass(this.nativeElement, `review-rate--${this.reviewType}`);
    }
    if (newReviewType) {
      this.renderer2.addClass(this.nativeElement, `review-rate--${newReviewType}`);
    }
  }

  get nativeElement() {
    return this.elementRef.nativeElement;
  }

}
