import { ReviewCodeEnum } from '../enums/review.enum';

export const ReviewOptionsList = [
  {
    alt: 'Muito Ruim',
    color: 'negative',
    id: ReviewCodeEnum.TOO_BAD,
    img: '/assets/images/review/too_bad.svg',
    label: 'Muito Ruim'
  },
  {
    alt: 'Ruim',
    color: 'bad',
    id: ReviewCodeEnum.BAD,
    img: '/assets/images/review/bad.svg',
    label: 'Ruim'
  },
  {
    alt: 'Neutro',
    color: 'neutral',
    id: ReviewCodeEnum.NEUTRAL,
    img: '/assets/images/review/neutral.svg',
    label: 'Neutro'
  },
  {
    alt: 'Bom',
    color: 'good',
    id: ReviewCodeEnum.GOOD,
    img: '/assets/images/review/good.svg',
    label: 'Bom'
  },
  {
    alt: 'Ótimo',
    color: 'positive',
    id: ReviewCodeEnum.LOVE,
    img: '/assets/images/review/love.svg',
    label: 'Ótimo'
  }
];
