import { Component } from '@angular/core';
import { ModalFeedbackType, ModalFeedbackView } from '@trato-logistica/trato-web-ui';
import { ModalFeedbackBaseComponent } from '../modal-feedback-base';

@Component({
  templateUrl: '../modal-feedback-base.html',
})
export class ModalGenericSuccessComponent extends ModalFeedbackBaseComponent {
  get type(): ModalFeedbackType {
    return ModalFeedbackView.SUCCESS;
  }
}
