import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Timer {
  initialTime = 0;
  timeStopped = 0;

  startingTime() {
    if (!this.initialTime) {
      this.initialTime = new Date().getTime();
    }
  }

  get totalTime(): number {
    if (!this.initialTime) {
      return 0;
    }
    return (new Date().getTime() - this.initialTime) / 1000;
  }

  get totalTimeInMiliseconds(): number {
    if (!this.initialTime) {
      return 0;
    }
    return new Date().getTime() - this.initialTime;
  }

  get lastInteractionTime(): number {
    return this.timeStopped;
  }

  stopTime() {
    if (!this.initialTime) {
      return 0;
    }
    this.timeStopped = (new Date().getTime() - this.initialTime) / 1000;
  }

  resetTime() {
    this.initialTime = 0;
  }
}
