import { Injectable } from "@angular/core";
import { Origin } from "sharedCommon/enums/origin.enum";

@Injectable({ providedIn: 'root' })
export class FlowOriginService {
  

  getOriginStore(): string {
    return sessionStorage.getItem('origin') ?? Origin.CHAPA;
  }

  setOriginStore(value: string) {
    sessionStorage.setItem('origin', value);
  }

  clearOriginStore() {
    sessionStorage.removeItem('origin');
  }
}
