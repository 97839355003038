import { Injectable } from '@angular/core';
import { UserStore } from '@trato-logistica/trato-auth';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BaseGaTypeView } from 'sharedCommon/classes/base-ga.view';
import { BaseGAService } from 'sharedCommon/classes/base.ga.service';
import { Timer } from 'sharedCommon/services/timer/timer';
import { ReviewRateTypeView } from '../classes/review-rate-ga.view';

@Injectable({
  providedIn: 'root',
})
export class ReviewGAService extends BaseGAService {
  static readonly CATEGORY = 'sat_management';

  descriptionValueFirstFill = true;

  constructor(
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected timer: Timer,
    protected userStore: UserStore,
  ) {
    super(googleAnalyticsService, timer, userStore);
  }

  handleInputGA(value?: string) {
    const params = this.getTimingParams(ReviewGAService.CATEGORY);
    if (value) {
      const gaType = this.descriptionValueFirstFill ? BaseGaTypeView.FILL : BaseGaTypeView.REFILL;
      this.event(`${ReviewRateTypeView.REVIEW}_${gaType}`, params);
    } else if (!this.descriptionValueFirstFill) {
      this.event(`${ReviewRateTypeView.REVIEW}_${BaseGaTypeView.CLEAR}`, params);
    }
    this.descriptionValueFirstFill = false;
  }

  onSelectClick(value: string) {
    this.event(`${ReviewRateTypeView.REVIEW_OPTION}_${BaseGaTypeView.SELECT_CLICK}`, {
      ...this.getTimingParams(ReviewGAService.CATEGORY),
      value,
    });
  }

  onConfirmClick() {
    this.event(
      `${ReviewRateTypeView.REVIEW}_${BaseGaTypeView.CONFIRM_CLICK}`,
      this.getTimingParams(ReviewGAService.CATEGORY),
    );
  }

  onCancelClick() {
    this.event(
      `${ReviewRateTypeView.REVIEW}_${BaseGaTypeView.CANCEL_CLICK}`,
      this.getTimingParams(ReviewGAService.CATEGORY),
    );
  }

  onCloseClick() {
    this.event(
      `${ReviewRateTypeView.REVIEW}_${BaseGaTypeView.CLOSE_CLICK}`,
      this.getTimingParams(ReviewGAService.CATEGORY),
    );
  }

  onLoadSuccess(value) {
    this.event(`${ReviewRateTypeView.REVIEW}_${BaseGaTypeView.LOAD_SUCCESS}`, {
      ...this.getBaseParams(ReviewGAService.CATEGORY),
      value: value,
    });
  }

  onUnexpectedError() {
    this.event('sat_review_load_unexpectederror', this.getBaseParams(ReviewGAService.CATEGORY));
  }
}
