import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import br from '@angular/common/locales/pt';
import { ApplicationRef, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AuthState, TratoAuthModule } from '@trato-logistica/trato-auth';
import { TratoPortalModule } from '@trato-logistica/trato-web-ui';
import { AgmDirectionModule } from 'agm-direction';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxsEffectsModule } from 'ngxs-effects';
import { TravelPanelPaginationState } from 'plan/travel-panel/state/travel-panel.state';
import { FilterSaverState } from 'sharedServices/filter-saver/filter-saver.state';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorsProviders } from './core/interceptors';
import { LoaderModule } from './core/loader/loader.module';
import { ReviewFeedbackModule } from './core/shell/components/review/review.module';
import { BatchPaginationState } from './modules/plan/batch/state/batch.state';
import { DashboardFilterState } from './modules/plan/dashboard/state/dashboard-filter.state';
import { SlotState } from './modules/sat/slots/state/slot.state';
import { ModalFeedbackModule } from './shared/common/components/modal-feedback/modal-feedback.module';
import { ConnectionService } from './shared/connection/connection.service';
import { ConnectionState } from './shared/connection/state/connection.state';
import { SchedulingState } from './shared/sat/state/scheduling/scheduling.state';
registerLocaleData(br, 'pt-BR');

const STATES = [
  AuthState,
  SlotState,
  DashboardFilterState,
  SchedulingState,
  ConnectionState,
  BatchPaginationState,
  FilterSaverState,
  TravelPanelPaginationState,
];

const NGXS = [
  NgxsModule.forRoot(STATES, {
    developmentMode: !environment.production,
  }),
  NgxsEffectsModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot(),
  NgxsReduxDevtoolsPluginModule.forRoot({
    disabled: environment.production,
  }),
];

const NGX = [
  NgxMaskModule.forRoot(),
  NgxPermissionsModule.forRoot(),
  NgxGoogleAnalyticsModule.forRoot(environment.googleSettings.GOOGLE_ANALYTICS),
  NgxGoogleAnalyticsRouterModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ...NGXS,
    ...NGX,
    LoaderModule,
    TratoPortalModule,
    ModalFeedbackModule,
    ReviewFeedbackModule,
    TratoAuthModule.forRoot({
      keycloakConfig: environment.KEYCLOAK_CONFIG,
      gwKey: environment.googleSettings.GW_APP_KEY,
    }),
    AgmDirectionModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }, httpInterceptorsProviders, ConnectionService],
})
export class AppModule implements DoBootstrap {
  constructor(private connectionService: ConnectionService) {}

  ngDoBootstrap(appRef: ApplicationRef): void {
    this.connectionService.startMonitor();
    appRef.bootstrap(AppComponent);
  }
}
