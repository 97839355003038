import { AfterViewInit, Component, Inject } from '@angular/core';
import {
    GenericPortalRef,
    ModalFeedbackActionView,
    ModalFeedbackType,
    PORTAL_DATA,
} from '@trato-logistica/trato-web-ui';
import { isString } from 'sharedCommon/utils/string.util';
import { GENERIC_CONFIRMATION_MESSAGE } from '../../constants/generic-error.constants';
import { ModalData } from './interfaces/modal.interface';

@Component({
  template: '',
})
export abstract class ModalFeedbackBaseComponent implements AfterViewInit {
  ModalFeedbackActionView = ModalFeedbackActionView;

  data: ModalData;

  constructor(@Inject(PORTAL_DATA) private genericPortalRef: GenericPortalRef) {
    this.defineData(genericPortalRef.data);
  }

  abstract get type(): ModalFeedbackType;

  get bodyText(): string {
    return this.data.message;
  }

  get confirmText(): string {
    return this.data?.confirmText || GENERIC_CONFIRMATION_MESSAGE;
  }

  get cancelText(): string {
    return;
  }

  get hasCancelAction(): boolean {
    return !!this.cancelText;
  }

  ngAfterViewInit(): void {
    // prevents keyboard actions behind opened portal
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  close = (data?: any): void => this.genericPortalRef.close(data);

  private defineData(data: any) {
    this.data = isString(data)
      ? ({
          message: data,
        } as ModalData)
      : (data as ModalData);
  }
}
