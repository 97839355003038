import { Injectable } from '@angular/core';
import { Action, StateContext, Selector, StateToken, State } from '@ngxs/store';
import { DashboardFilter } from '../interfaces/dashboard.interface';
import { SaveDashboardFilter } from './dashboard-filter';

const DASHBOARD_FILTER_STATE = new StateToken<DashboardFilter>('dashboardFilter');

@State<DashboardFilter>({
    name: DASHBOARD_FILTER_STATE,
    defaults: undefined
})

@Injectable()
export class DashboardFilterState {
    @Selector()
    static getDashboardFilter(state: DashboardFilter): DashboardFilter {
        return state;
    }

    @Action(SaveDashboardFilter)
    saveDashboardFilter({ setState }: StateContext<DashboardFilter>, action: SaveDashboardFilter) {
        const filter: DashboardFilter = action.dashboardFilter;
        setState(filter);
    }
}
