import { SchedulingData } from './scheduling-data.interface';

export class SchedActionSetExternalId {
    static readonly type = '[Scheduling] Define externalId for details';
    constructor(public externalId: string) { }
}

export class SchedActionSetData {
    static readonly type = '[Scheduling] Define data to edit';
    constructor(public shedulingData: SchedulingData) { }
}

export class SchedActionReset {
    static readonly type = '[Scheduling] Clear current data';
}
