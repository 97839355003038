import { DialogDataId } from '../interfaces/dialog-data-id.interface';

export const GENERIC_CONFIRMATION_MESSAGE = 'Ok, entendi';
export const GENERIC_ERROR_MESSAGE = 'Erro inesperado. Tente novamente mais tarde.';

export const GENERIC_ERROR_DIALOG_DATA_ID: DialogDataId = {
    id: 'trato-modal-error',
    dialogData: {
        icon: 'exclamation-mark',
        classes: 'feedback-error',
        message: GENERIC_ERROR_MESSAGE
    }
};
