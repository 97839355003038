export const keyDev = {
  GOOGLE_ANALYTICS: `UA-163495278-3`,
  GW_APP_KEY: '3a7b4430f4f60138a1a846c283d72a46',
  GM_KEY: 'AIzaSyBe6dw-a0ZsyTS8uW-7NEU0HBD_-BnCbU8',
  FIREBASE_API_KEY: 'AIzaSyDjQLC0VtKaxBDeCh3yYNMN7R-z9cyP5OY',
};

export const keyStg = {
  GOOGLE_ANALYTICS: `UA-163495278-3`,
  GW_APP_KEY: '3a7b4430f4f60138a1a846c283d72a46',
  GM_KEY: 'AIzaSyBIO6_5qsUGXCriNI_FV2ynxwsHDuYA0X0',
  FIREBASE_API_KEY: 'AIzaSyBvDjjYMgAbUoZTviVwsijxjb-TL4Rxfqk',
};

export const keyProd = {
  GOOGLE_ANALYTICS: `UA-163495278-5`,
  GW_APP_KEY: '3a7b4430f4f60138a1a846c283d72a46',
  GM_KEY: 'AIzaSyDFjv1kaEBu_Vv2dvhNlOSMcmUNRhy4RKY',
  FIREBASE_API_KEY: 'AIzaSyDb5-Bp0dkftedUXY36SWm98zGvlFv33pM',
};

export const key = {
  GOOGLE_ANALYTICS: `UA-163495278-3`,
  GW_APP_KEY: '3a7b4430f4f60138a1a846c283d72a46',
  GM_KEY: 'AIzaSyBe6dw-a0ZsyTS8uW-7NEU0HBD_-BnCbU8',
  FIREBASE_API_KEY: 'AIzaSyDjQLC0VtKaxBDeCh3yYNMN7R-z9cyP5OY',
};
