import { BaseGaTypes } from 'sharedCommon/classes/base-ga.types';

export class BaseGaTypeView {
  static ACCORDION_CLICK: BaseGaTypes = 'accordion_click';
  static ACTION_DELETE: BaseGaTypes = 'action_delete';
  static ACTION_EDIT: BaseGaTypes = 'action_edit';
  static ADDCONTRACT_CLICK: BaseGaTypes = 'addcontract_click';
  static ADDMODAL_CANCEL: BaseGaTypes = 'addmodal_cancel';
  static ADDMODAL_END: BaseGaTypes = 'addmodal_end';
  static ADDPLATEMODAL_CANCEL: BaseGaTypes = 'addplatemodal_cancel';
  static ADDPLATEMODAL_END: BaseGaTypes = 'addplatemodal_end';
  static ADDSCHEDULE_CLICK: BaseGaTypes = 'addschedule_click';
  static ADDVEHICLE_CLICK: BaseGaTypes = 'addvehicle_click';
  static ADD_CLICK: BaseGaTypes = 'add_click';
  static ADD_ERROR: BaseGaTypes = 'add_error';
  static BACK_CLICK: BaseGaTypes = 'back_click';
  static BUTTON_CANCEL_CLICK: BaseGaTypes = 'button_cancel_click';
  static BUTTON_CONCLUDE_CLICK: BaseGaTypes = 'button_conclude_click';
  static CANCEL_CLICK: BaseGaTypes = 'cancel_click';
  static CLEAR: BaseGaTypes = 'clear';
  static CLEAR_CLICK: BaseGaTypes = 'clear_click';
  static CLOSE: BaseGaTypes = 'close';
  static CLOSED: BaseGaTypes = 'closed';
  static CLOSE_CLICK: BaseGaTypes = 'close_click';
  static CONFIRM_CLICK: BaseGaTypes = 'confirm_click';
  static CONTINUE_CLICK: BaseGaTypes = 'continue_click';
  static Click: BaseGaTypes = 'click';
  static DELETEMODAL_CANCEL: BaseGaTypes = 'deletemodal_cancel';
  static DELETEMODAL_DELETE: BaseGaTypes = 'deletemodal_delete';
  static EDITCONTRACT_CLICK: BaseGaTypes = 'editcontract_click';
  static EDITMODAL_CANCEL: BaseGaTypes = 'editmodal_cancel';
  static EDITMODAL_END: BaseGaTypes = 'editmodal_end';
  static EDITMODAL_NO: BaseGaTypes = 'editmodal_no';
  static EDITMODAL_YES: BaseGaTypes = 'editmodal_yes';
  static EDIT_CLICK: BaseGaTypes = 'edit_click';
  static EXPAND_CLICK: BaseGaTypes = 'expand_click';
  static FILL: BaseGaTypes = 'fill';
  static HOVER: BaseGaTypes = 'hover';
  static LOAD_AUTO: BaseGaTypes = 'load_auto';
  static LOAD_EMPTY_GRID_ERROR: BaseGaTypes = 'load_emptygriderror';
  static LOAD_ERROR: BaseGaTypes = 'load_error';
  static LOAD_FORM: BaseGaTypes = 'load_form';
  static LOAD_NOT_FOUND_ERROR: BaseGaTypes = 'load_notfounderror';
  static LOAD_SUCCESS: BaseGaTypes = 'load_success';
  static LOAD_WARNING: BaseGaTypes = 'load_warning';
  static MENU_CLICK: BaseGaTypes = 'menu_click';
  static MODAL_BUTTON_CANCEL: BaseGaTypes = 'modal_button_cancel';
  static MODAL_BUTTON_NO: BaseGaTypes = 'modal_button_no';
  static MODAL_BUTTON_SAVE: BaseGaTypes = 'modal_button_save';
  static MODAL_BUTTON_YES: BaseGaTypes = 'modal_button_yes';
  static NEW_CONTRACT_CLICK: BaseGaTypes = 'new_contract_click';
  static OPEN: BaseGaTypes = 'open';
  static OPENED: BaseGaTypes = 'opened';
  static OPEN_CLICK: BaseGaTypes = 'open_click';
  static PIN: BaseGaTypes = 'pin';
  static PIN_CLICK: BaseGaTypes = 'pin_click';
  static INSPECTION_FILL: BaseGaTypes = 'inspectionfill_open';
  static REFILL: BaseGaTypes = 'refill';
  static SELECT: BaseGaTypes = 'select';
  static SELECT_CLICK: BaseGaTypes = 'select_click';
  static SEL_CLICK: BaseGaTypes = 'sel_click';
  static TERMINALTOGGLE_CLICK: BaseGaTypes = 'terminaltoggle_click';
  static TERMINALTOGGLE_PAUSE: BaseGaTypes = 'terminalpause_cancel';
  static UNSELECT_CLICK: BaseGaTypes = 'unselect_click';
  static FEEDBACK_ERROR: BaseGaTypes = 'feedback_error';
  static SENT: BaseGaTypes = 'sent';
}
