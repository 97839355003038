import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class CryptographyService {
  encryptedBase64Key = 'dHJhdG8tZW5jcnktMSNAIQ==';
  parsedBase64Key = CryptoJS.enc.Base64.parse(this.encryptedBase64Key);

  public encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
      .toString()
      .replace(/\//g, 'BARSIGN')
      .replace(/\?/g, 'INTERROGSIGN')
      .replace(/\"/g, 'QUOTATIONSIGN');
  }

  public decrypt(textToDecrypt: string): any {
    return CryptoJS.AES.decrypt(textToDecrypt, this.parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
      .toString(CryptoJS.enc.Utf8)
      .replace(/BARSIGN/g, '/')
      .replace(/INTERROGSIGN/g, '?')
      .replace(/QUOTATIONSIGN/g, '"');
  }
}
