import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpStatus, UserStore } from '@trato-logistica/trato-auth';
import { GenericPortalRef, PORTAL_DATA, PortalService } from '@trato-logistica/trato-web-ui';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { ModalGenericErrorComponent } from 'sharedCommon/components/modal-feedback/generic-error/generic-error.component';
import { ModalGenericSuccessComponent } from 'sharedCommon/components/modal-feedback/generic-success/generic-success.component';
import { DialogDataId } from 'sharedCommon/interfaces/dialog-data-id.interface';
import { TratoError } from 'sharedCommon/interfaces/trato-error-response.interface';
import { Timer } from 'sharedCommon/services/timer/timer';
import { ReviewConfConstants } from './constants/review-configuration.constant';
import { ReviewCodeEnum } from './enums/review.enum';
import { ReviewRequest } from './interfaces/review.interface';
import { ReviewRateContainerComponent } from './review-rate-container/review-rate-container.component';
import { ReviewGAService } from './services/review.ga.service';
import { ReviewService } from './services/review.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly modalReviewId: string = 'review-modal';
  readonly maxLengthDescription = ReviewConfConstants.maxLengthDescription;
  isLoading = false;
  isCompleted = false;

  formReview: UntypedFormGroup;
  modal: DialogDataId;
  selectedReviewCode: string;
  stateChangesSubscription: Subscription;
  reviewServiceSubscription: Subscription;

  @ViewChild(ReviewRateContainerComponent) reviewRateContainer: ReviewRateContainerComponent;

  constructor(
    @Inject(PORTAL_DATA) private genericPortalRef: GenericPortalRef,
    private reviewService: ReviewService,
    private portalService: PortalService,
    private formBuilder: UntypedFormBuilder,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected timer: Timer,
    protected userStore: UserStore,
    private gaService: ReviewGAService,
  ) {}

  ngOnInit() {
    this.buildForm();
    this.onDescriptionChange();
    this.gaService.startTimer();
  }

  ngAfterViewInit() {
    this.stateChangesSubscription = this.reviewRateContainer.stateChanges.subscribe(
      (res) => (this.selectedReviewCode = res),
    );
  }

  ngOnDestroy() {
    if (this.stateChangesSubscription) {
      this.stateChangesSubscription.unsubscribe();
    }
    if (this.reviewServiceSubscription) {
      this.reviewServiceSubscription.unsubscribe();
    }
  }

  buildForm() {
    this.formReview = this.formBuilder.group({
      description: ['', [Validators.maxLength(this.maxLengthDescription)]],
    });
  }

  send() {
    if (this.formReview.valid) {
      this.gaService.onConfirmClick();
      const request: ReviewRequest = {
        satisfaction: this.selectedReviewCode,
        description: this.formReview.get('description').value || '',
      };

      this.reviewService.sendReview(request).subscribe({
        next: () => this.onSuccess(),
        error: (e) => this.onError(e),
      });
    }
  }

  public cancel() {
    this.gaService.onCancelClick();
    this.genericPortalRef.close();
  }

  public isPositiveRate() {
    return this.selectedReviewCode === ReviewCodeEnum.LOVE || this.selectedReviewCode === ReviewCodeEnum.GOOD;
  }

  public isNeutralRate() {
    return this.selectedReviewCode === ReviewCodeEnum.NEUTRAL;
  }

  public isNegativeRate() {
    return this.selectedReviewCode === ReviewCodeEnum.BAD || this.selectedReviewCode === ReviewCodeEnum.TOO_BAD;
  }

  public hasError(formControl: AbstractControl, errorName: string) {
    return formControl.touched && formControl.hasError(errorName);
  }

  private onDescriptionChange() {
    this.formReview.get('description').valueChanges.subscribe((value: string) => {
      this.gaService.handleInputGA(value);
    });
  }

  onError(error: TratoError) {
    this.genericPortalRef.close();
    if (error?.status === HttpStatus.EXPECTATION_FAILED) {
      this.portalService.openComponent(
        ModalGenericErrorComponent,
        `Eita! <br>Aconteceu algum erro no envio da sua sugestão.<br> Tenta novamente daqui alguns minutos...<br>Queremos muito saber o que você tem para compartilhar!`,
      );
    }
  }

  onSuccess() {
    this.gaService.onLoadSuccess(this.selectedReviewCode);
    this.genericPortalRef.close();
    this.portalService.openComponent(ModalGenericSuccessComponent, `Obrigado por compartilhar sua opinião!`);
  }
}
