import { devEnvFirebase, prdEnvFirebase, stgEnvFirebase } from './constants';

export class GoogleConnections {
  googleSettings(key) {
    return {
      GOOGLE_ANALYTICS: key.GOOGLE_ANALYTICS,
      GW_APP_KEY: key.GW_APP_KEY,
      GM_KEY: key.GM_KEY,
    };
  }

  firebaseSettings(key, type: string) {
    const FIREBASE_API_KEY = key.FIREBASE_API_KEY;
    const FIREBASE_URL_CREATE_DYNAMIC_LINKS = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_API_KEY}`;

    const envFirebase = this.validationEnvFirebase(FIREBASE_API_KEY, FIREBASE_URL_CREATE_DYNAMIC_LINKS, type);

    const FIREBASE_DYNAMIC_LINKS_CONFIG = envFirebase.FIREBASE_DYNAMIC_LINKS_CONFIG;
    const CHAPA_FIREBASE_DYNAMIC_LINKS_CONFIG = envFirebase.CHAPA_FIREBASE_DYNAMIC_LINKS_CONFIG;

    return { FIREBASE_DYNAMIC_LINKS_CONFIG, CHAPA_FIREBASE_DYNAMIC_LINKS_CONFIG };
  }

  validationEnvFirebase(firebaseDinamicLink: string, firebaseUrl: string, type: string) {
    const envConfig = {
      DEV: devEnvFirebase,
      STG: stgEnvFirebase,
      PRD: prdEnvFirebase,
    };

    const selectedEnv = envConfig[type];

    if (!selectedEnv) {
      throw new Error(`Invalid environment type: ${type}`);
    }

    const urlFirebase = {
      domainPrefix: selectedEnv.domainPrefix,
      app: selectedEnv.app,
      router: selectedEnv.router,
      chapaDomainPrefix: selectedEnv.chapaDomainPrefix,
    };

    const FIREBASE_DYNAMIC_LINKS_CONFIG = {
      key: firebaseDinamicLink,
      url: firebaseUrl,
      domain_prefix: urlFirebase.domainPrefix,
      app: urlFirebase.app,
      route: urlFirebase.router,
    };

    const CHAPA_FIREBASE_DYNAMIC_LINKS_CONFIG = {
      ...FIREBASE_DYNAMIC_LINKS_CONFIG,
      domain_prefix: urlFirebase.chapaDomainPrefix,
    };

    return { FIREBASE_DYNAMIC_LINKS_CONFIG, CHAPA_FIREBASE_DYNAMIC_LINKS_CONFIG };
  }
}
