export function environmentSettings(type: string) {
  switch (type) {
    case 'PRD':
      return {
        url: 'https://iam.trato.com.br/auth',
        path: 'https://api.trato.com.br',
      };
    case 'STG':
      return {
        url: 'https://iam.stg.trato.com.br/auth',
        path: 'https://api-hml.trato.com.br',
      };
    default:
      return {
        url: 'https://iam.dev.trato.com.br/auth',
        path: 'https://api-dev.trato.com.br',
      };
  }
}
