import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = new BehaviorSubject(false);

  setLoading(state: boolean) {
    this.isLoading.next(state);
  }
}
