import { TravelPanelStoreInterface } from './travel-panel-store.interface';

export class TravelPanelPaginationActionSet {
  static readonly type = '[TravelPanelPagination] Define filter and pagination';
  constructor(public travelPanelPagination: TravelPanelStoreInterface) {}
}

export class TravelPanelPaginationActionReset {
  static readonly type = '[TravelPanelPagination] Clear current filter and pagination';
}
