<trato-modal-feedback [type]="type" (closeAction)="close()">
    <trato-modal-body [innerHTML]="bodyText" id="test-automation-modal-feedback-message">
    </trato-modal-body>
    <trato-modal-button *ngIf="confirmText !== 'false'"
        [text]="confirmText"
        (clickAction)="close(ModalFeedbackActionView.CONFIRM)"></trato-modal-button>
    <trato-modal-button
        *ngIf="hasCancelAction"
        [text]="cancelText"
        (clickAction)="close(ModalFeedbackActionView.CANCEL)"></trato-modal-button>
</trato-modal-feedback>