export const devEnvFirebase = {
    domainPrefix: 'https://apptratodev.page.link',
    app: 'br.com.apptrato.dev',
    router: 'dev.navigate.freight.contract',
    chapaDomainPrefix: 'https://chapatratodev.page.link',
}

export const stgEnvFirebase = {
    domainPrefix: 'https://apptratostg.page.link',
     app: 'br.com.apptrato.stg',
     router: 'stg.navigate.freight.contract',
     chapaDomainPrefix: 'https://chapatratostg.page.link',
}

export const prdEnvFirebase = {
    domainPrefix: 'https://apptratoprod.page.link',
    app: 'br.com.apptrato',
    router: 'prd.navigate.freight.contract',
    chapaDomainPrefix: 'https://chapatratoprd.page.link',
}