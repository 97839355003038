import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { REVIEW_RATE_CONTAINER } from '../constants/review-rate.constant';
import { ReviewBaseComponent, ReviewComponentItem } from '../interfaces/review.interface';
import { ReviewRateItemComponent } from '../review-rate-item/review-rate.component';
import { ReviewCodeEnum } from '../enums/review.enum';
import { ReviewOptionsList } from '../constants/review-options-list';
import { ReviewGAService } from '../services/review.ga.service';

@Component({
  selector: 'app-review-rate-container',
  templateUrl: './review-rate-container.component.html',
  styleUrls: ['./review-rate-container.component.scss'],
  providers: [
    {
      provide: REVIEW_RATE_CONTAINER,
      useExisting: ReviewRateContainerComponent,
    },
  ],
})
export class ReviewRateContainerComponent implements ReviewBaseComponent {
  stateChanges: Subject<string>;

  reviewCodeEnum = ReviewCodeEnum;

  reviewOptionsList: ReviewComponentItem[] = [];

  private ownListItems = new Array<{ component: ReviewRateItemComponent; subscription: Subscription }>();

  constructor(private gaService: ReviewGAService) {
    this.stateChanges = new Subject();
    this.reviewOptionsList = ReviewOptionsList;
  }

  registerItem(itemComponent: ReviewRateItemComponent): void {
    this.unsubscribeIfHasItem(itemComponent);
    this.ownListItems.push({
      component: itemComponent,
      subscription: this.subscribeToExpandChange(itemComponent),
    });
  }

  removeItem(itemComponent: ReviewRateItemComponent): void {
    this.unsubscribeIfHasItem(itemComponent);
  }

  clearSelection() {
    this.ownListItems.forEach((element) => {
      element.component.selected = false;
    });
  }

  private subscribeToExpandChange(itemComponent: ReviewRateItemComponent) {
    return itemComponent.clickFn.subscribe(() => this.onClickItem(itemComponent));
  }

  private onClickItem(itemComponent: ReviewRateItemComponent) {
    this.gaService.onSelectClick(itemComponent.id);
    this.ownListItems.forEach((element) => {
      if (element.component !== itemComponent) {
        element.component.selected = false;
      }
    });
    itemComponent.selected = true;
    this.stateChanges.next(itemComponent.id);
  }

  private unsubscribeIfHasItem(itemComponent: ReviewRateItemComponent) {
    const index = this.ownListItems.findIndex((l) => l.component === itemComponent);
    if (index >= 0) {
      this.ownListItems[index].subscription.unsubscribe();
      this.ownListItems.splice(index, 1);
    }
  }
}
