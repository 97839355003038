export function limitCharacters(text: string, limit: number): string {
  if (!text) {
    return '';
  }
  return `${text.substring(0, limit)}${text.length > limit ? '...' : ''}`;
}

export function getPluralTextBySize(size: number, singular: string, plural: string): string {
  return size === 1 ? singular : plural;
}

export function isString(value): boolean {
  return typeof value === 'string' || toString.call(value) == '[object String]';
}

export function buildTwoWordWithSeparator(strOne: string, strTwo: string, separator = '-'): string {
  return `${strOne} ${separator} ${strTwo}`;
}

export function replaceIfExists(input: string | number, toReplace: string, replacement: string): string {
  const strInput = input?.toString();
  if (strInput?.includes(toReplace)) {
    return strInput?.replace(toReplace, replacement);
  }
  return strInput;
}
