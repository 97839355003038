import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConnectionClearState, ConnectionStartMonitor } from './connection-actions.state';
import { ConnectionData } from './connection-data.interface';
import { ConnectionState } from './connection.state';

@Injectable({
    providedIn: 'root'
})
export class ConnectionStore {

    @Select(ConnectionState.data) data$: Observable<ConnectionData>;

    constructor(private store: Store) {
        this.clearState();
    }

    startMonitor(): void {
        this.store.dispatch(ConnectionStartMonitor);
    }

    clearState(): void {
        this.store.dispatch(ConnectionClearState);
    }

    get isOnline(): boolean {
        return this.store.selectSnapshot(ConnectionState.isOnline);
    }
}
