import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TratoModalModule } from '@trato-logistica/trato-web-ui';
import { GenericModalChapaComponent } from './generic-custom/generic-custom.component';
import { ModalGenericErrorComponent } from './generic-error/generic-error.component';
import { ModalGenericInfoComponent } from './generic-info/generic-info.component';
import { ModalGenericQuestionComponent } from './generic-question/generic-question.component';
import { ModalGenericSuccessComponent } from './generic-success/generic-success.component';

const tratoModules = [TratoModalModule];

const components = [
  ModalGenericErrorComponent,
  ModalGenericSuccessComponent,
  ModalGenericQuestionComponent,
  ModalGenericInfoComponent,
  GenericModalChapaComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, tratoModules],
  exports: components,
})
export class ModalFeedbackModule {}
