import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OriginUrlInterceptor } from 'sharedCommon/interceptors/origin-url.interceptor';
import { OriginErrorInterceptor } from 'src/app/modules/chapa/interceptors/origin-error.interceptor';
import { BlobErrorHttpInterceptor } from './blob-error-http.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { LoaderInterceptor } from './loader.interceptor';
import { ReviewInterceptor } from './review.interceptor';

export const httpInterceptorsProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ReviewInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: OriginUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: OriginErrorInterceptor, multi: true }
];
